<template>
  <div :class="{ contain: true, mobile: isMobile }">
    <div class="content d-flex flex-column flex-lg-row mb-16">
      <div class="left ml-lg-8 mr-lg-5">
        <div class="PromoImage" >
          <img src="@/assets/promoImage/image_wedding.jpg" alt="" />
        </div>  
        <div class="btn mt-6">
          <!-- <button class="notClaim" @click="onClicked">
            {{ $t("admin_activity_btn_claim") }}
          </button> -->
           <button class="claim" @click="onClicked">
            {{ $t("token_state_end") }}
          </button>
        </div>
      </div>
      <div class="right mt-lg-0 mt-9">
        <div class="title mt-2 mb-6">
          <span class="">MetaWedding - Rita & André</span>
        </div>
        <div class="desc">
          <div class="mb-6">
            Together with their families and friends, the Brazilians André
            Mertens and Rita Wu have the honor of announcing their meta marriage
            on March 19th, 2022.
          </div>
          <div class="mb-6">
            Join us on our special meta wedding. You will be part of a
            first-time experience on Decentraland and make history with us.
          </div>
          <div class="mb-6">
            To commemorate with friends, families, and the Decentraland
            community, attending guests will have the chance of collecting a
            (limited) one-of-a-kind NFT (1000 units in Uptick) and Poaps, based
            on the real proposal ring.
          </div>
        </div>
        <div class="claimtime">
          <div class="">
            Church opens at : 19:00pm (GMT-3) Ceremony Starts: 19:30pm (GMT-3)
          </div>
          <div class="unline mb-6" @click="newLinkBtn">
            Official invitation >
          </div>
          <div class="">Ceremony location: -61.98</div>
          <div class="mb-6">Party location: 43.-46</div>
          <div class="">Event period to claim NFT：19:30pm (GMT-3)</div>
          <div class="">Event Rules：It's only 1000 NFTs, no more mint</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from "@/api";
export default {
  data() {
    return {
      d1: "",
      d2: "",
      h1: "",
      h2: "",
      m1: "",
      m2: "",
      s1: "",
      s2: "",
      value: {
        did: "iaa1utsw7gm82s5wecmvthjg5p58r6dmf7s8s0ls0z",
        profilePhoto: "QmQmPxA9FpfMU7DKCSSwmTrdcTFyMVHyWrhXYA7BeawYjZ",
      },
      isConstellationEnd: false,
      isStart: false,
      isClaimed: false,
      isClicked: false,
      PromoClaim: false,
      isJurisdiction: false,
    };
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.mobile;
    },
  },
  async mounted() {
    this.Time();
    var timestamp = Date.parse(new Date()).toString();
    let nowTimeStamp = timestamp.substr(0, 10);
    // 星座结束时间
    let constellationEndTimeStamp = 1650283200; //1650283200
    let constellationStartTimeStamp = 1647604800; //1647604800

    if (nowTimeStamp > constellationEndTimeStamp) {
      this.isConstellationEnd = true;
    } else {
      this.isConstellationEnd = false;
    }

    let params = {
      //  did:"iaa1r44svrd44syk7g79z3h53chg2ar624l8fc5ap5",
      id: "206",
    };

    // 判断领取状态
    if (nowTimeStamp < constellationStartTimeStamp) {
      this.isStart = false;
      this.isClicked = false;
      this.isJurisdiction = true;
    } else if (nowTimeStamp > constellationStartTimeStamp) {
      let res = await api.home.getPromoUser(params);

      this.isStart = true;
      if (res.data != "") {
        this.isJurisdiction = true;
        if (res.data[0].isReceive == true) {
          this.isClicked = true;
        } else {
          this.isClicked = false;
        }
      } else {
        this.isJurisdiction = false;
      }
    }
  },
  methods: {
    onClicked() {
      window.location.href =
        "https://irisnet.upticknft.com/otherpage?did=iaa1xs4vj9cjryc8zmzygkwxmxjvnzpqznc8xdd67r";
    },
    getTime() {
      // 摄像作品结束时间
      let photographyEndTimeStamp = 1650283200000;
      this.now = new Date().valueOf();

      this.end = photographyEndTimeStamp;

      if (this.now < this.end) {
        this.seconds = (this.end - this.now) / 1000;
      }
    },
    // 天 时 分 秒 格式化函数
    countDown() {
      let d = parseInt(this.seconds / (24 * 60 * 60));
      let day = d < 10 ? "0" + d : d;
      this.d1 = String(day).split("")[0];
      this.d2 = String(day).split("")[1];
      //let h = parseInt(((this.seconds / (60 * 60)) % 24) + d * 24);
      let h = parseInt((this.seconds / (60 * 60)) % 24);
      let hour = h < 10 ? "0" + h : h;
      this.h1 = String(hour).split("")[0];
      this.h2 = String(hour).split("")[1];
      let m = parseInt((this.seconds / 60) % 60);
      let minutes = m < 10 ? "0" + m : m;
      this.m1 = String(minutes).split("")[0];
      this.m2 = String(minutes).split("")[1];
      let s = parseInt(this.seconds % 60);
      let second = s < 10 ? "0" + s : s;
      this.s1 = String(second).split("")[0];
      this.s2 = String(second).split("")[1];
    },
    //定时器没过1秒参数减1
    Time() {
      setInterval(() => {
        if (this.seconds > 0) {
          this.seconds -= 1;
          this.countDown();
        } else {
          this.getTime();
        }
      }, 1000);
    },
    newLinkBtn() {
      window.open(
        "https://events.decentraland.org/event/?id=f13e1fea-9b2c-4c6a-946a-cb0266a8a3ac"
      );
    },
  },
};
</script>

<style lang="scss" scoped>
.contain {
  .content {
    margin-top: 50px;
    .left {
      //   width: 560px;
      // height: 550px;
      img {
        width: 413px;
        height: 413px;
        display: block;
        object-fit: contain;
        cursor: pointer;
        margin: 20px 94px 40px 106px;
      }
      .timer {
        // max-width:305px;
        // margin:17px auto 21px;
        justify-content: center;
        margin-top: 17px;
        .mao {
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 30px;
          letter-spacing: 0px;
          color: #270645;
        }
        .bom {
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 11px;
          font-weight: normal;
          font-stretch: normal;
          line-height: 20px;
          letter-spacing: 0px;
          color: #766983;
          text-align: center;
          margin-top: 7px;
        }
        span {
          width: 27px;
          height: 36px;
          display: inline-block;
          text-align: center;
          line-height: 36px;
          background-color: #270645;
          border-radius: 5px;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 18px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      .btn {
        // margin-left: 137px;
        text-align: center;
        .claim {
          width: 276px;
          height: 51px;
          border-radius: 25px;
          text-align: center;
          background-image: linear-gradient(#766983, #766983),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
        .notClaim {
          width: 276px;
          height: 51px;

          border-radius: 25px;
          text-align: center;
          background-image: linear-gradient(#270645, #270645),
            linear-gradient(
              90deg,
              #d300fd 0%,
              #a439fe 26%,
              #7471ff 52%,
              #00fdcf 100%
            ),
            linear-gradient(#270645, #270645);
          background-blend-mode: normal, normal, normal;
          font-family: "\5FAE\8F6F\96C5\9ED1";
          font-size: 23px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 51px;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
    .right {
      max-width: 588px;
      // height: 613px;
      .title {
        span {
          width: 575px;
          height: 124px;
          font-family: Helvetica;
          font-size: 55px;
          font-weight: bold;
          font-stretch: normal;
          line-height: 70px;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .desc {
        div {
          width: 588px;
          //   height: 215px;
          font-family: Helvetica;
          font-size: 15px;
          font-weight: bold;
          font-stretch: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
      .claimtime {
        .unline {
          font-family:Helvetica;
          text-decoration: underline;
          font-size: 13px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #270645;
          cursor: pointer;
        }
        div {
          font-family:Helvetica;
          font-size: 13px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #270645;
        }
      }
    }
  }
  &.mobile {
    .content {
      margin-top: 30px;
      .left {
        max-width: 100%;
        // min-height: 437px;
        img {
          width: 100%;
          height: 100%;
          display: block;
          object-fit: contain;
          cursor: pointer;
          margin: 0;
        }
        .btn {
          width: 80%;
          .claim {
            width: 80%;
          }
          .notClaim {
            margin-left: 10%;
          }
        }
      }
      .right {
        min-height: 613px;
        .title {
          margin-bottom: 34px;
          span {
            font-size: 25px;
            line-height: 40px;
          }
        }
        .desc {
          margin-bottom: 28px;
          .mb-6{
            width:100%;
          }
        }
        .claimtime {
          margin-bottom: 34px;
          div{
            width:100%;
          }
        }
      }
    }
  }
}
</style>
